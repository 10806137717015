var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "coninput",
      staticClass: "vs-component vs-con-input-label vs-input",
      class: [
        `vs-input-${_vm.color}`,
        {
          isFocus: _vm.isFocus,
          "input-icon-validate-success": _vm.success,
          "input-icon-validate-danger": _vm.danger,
          "input-icon-validate-warning": _vm.warning,
          "is-label-placeholder": _vm.labelPlaceholder,
        },
      ],
      style: _vm.styleLabel,
    },
    [
      (_vm.labelPlaceholder ? false : _vm.label)
        ? _c(
            "label",
            {
              staticClass: "vs-input--label",
              attrs: { for: "" },
              on: { click: _vm.focusInput },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vs-con-input" },
        [
          _vm.allowTinyMceRendering
            ? _c(
                "editor-tinymce",
                _vm._g(
                  {
                    ref: "vsinput",
                    staticClass:
                      "vs-inputx vs-input--input overflow-y-auto cursor-text reset-ul-ol-style",
                    class: [
                      _vm.size,
                      {
                        hasValue: _vm.value !== "",
                        hasIcon: _vm.icon,
                        "icon-after-input": _vm.iconAfter,
                      },
                    ],
                    style: _vm.style,
                    attrs: {
                      init: {
                        license_key: "gpl",
                        menubar: false,
                        plugins: _vm.plugins,
                        toolbar: _vm.toolbar,
                        font_size_formats:
                          "6px 7px 8px 9px 10px 11px 12px 13px 14px 16px 18px 20px 22px 24px 30px 36px 48px",
                        skin: false,
                        content_css: false,
                        language: _vm.$i18n.locale,
                        paste_as_text: true,
                      },
                      inline: "",
                      autofocus: _vm.autofocus,
                      placeholder: null,
                      value: _vm.value,
                      type: _vm.$attrs.type ? _vm.$attrs.type : "text",
                    },
                  },
                  _vm.listeners
                )
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "placeholderx" } }, [
            _vm.isValue && (_vm.labelPlaceholder || _vm.$attrs.placeholder)
              ? _c(
                  "span",
                  {
                    ref: "spanplaceholder",
                    staticClass: "input-span-placeholder vs-input--placeholder",
                    class: [
                      _vm.labelPlaceholder && _vm.size,
                      _vm.size,
                      {
                        "vs-placeholder-label": _vm.labelPlaceholder,
                      },
                    ],
                    style: _vm.styleLabel,
                    on: { click: _vm.focusInput },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$attrs.placeholder || _vm.labelPlaceholder) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.icon
            ? _c("vs-icon", {
                staticClass: "icon-inputx notranslate vs-input--icon",
                class: {
                  "icon-after": _vm.iconAfter,
                  "icon-no-border": _vm.iconNoBorder,
                },
                attrs: { "icon-pack": _vm.iconPack, icon: _vm.icon },
                on: {
                  click: function ($event) {
                    _vm.focusInput()
                    _vm.$emit("icon-click")
                  },
                },
              })
            : _vm._e(),
          _c("transition", { attrs: { name: "icon-validate" } }, [
            _vm.success || _vm.danger || _vm.warning
              ? _c(
                  "span",
                  {
                    staticClass: "input-icon-validate vs-input--icon-validate",
                    class: { "icon-before": _vm.iconAfter },
                  },
                  [
                    _c("vs-icon", {
                      class: { "icon-before": _vm.iconAfter },
                      attrs: {
                        "icon-pack": _vm.valIconPack,
                        icon: _vm.getIcon,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "transition-group",
        {
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            leave: _vm.leave,
          },
        },
        [
          _vm.success
            ? _c(
                "div",
                {
                  key: "success",
                  staticClass: "con-text-validation vs-input--text-validation",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "span-text-validation span-text-validation-success vs-input--text-validation-span",
                    },
                    [_vm._v(" " + _vm._s(_vm.successText) + " ")]
                  ),
                ]
              )
            : _vm.danger
            ? _c(
                "div",
                {
                  key: "danger",
                  staticClass:
                    "con-text-validation span-text-validation-danger vs-input--text-validation-span",
                },
                [
                  _c("span", { staticClass: "span-text-validation" }, [
                    _vm._v(" " + _vm._s(_vm.dangerText) + " "),
                  ]),
                ]
              )
            : _vm.warning
            ? _c(
                "div",
                {
                  key: "warning",
                  staticClass:
                    "con-text-validation span-text-validation-warning vs-input--text-validation-span",
                },
                [
                  _c("span", { staticClass: "span-text-validation" }, [
                    _vm._v(" " + _vm._s(_vm.warningText) + " "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.descriptionText
            ? _c(
                "div",
                {
                  key: "description",
                  staticClass:
                    "con-text-validation span-text-validation vs-input--text-validation-span",
                },
                [
                  _c("span", { staticClass: "span-text-validation" }, [
                    _vm._v(" " + _vm._s(_vm.descriptionText) + " "),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }