var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.invert
          ? _vm.vertical
            ? "fade-tab-vertical-invert"
            : "fade-tab-invert"
          : _vm.vertical
          ? "fade-tab-vertical"
          : "fade-tab",
      },
    },
    [
      _vm.active
        ? _c(
            "div",
            { staticClass: "con-tab vs-tabs--content" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }