var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "con-vs-tabs vs-tabs",
      class: [`vs-tabs-${_vm.color}`, `vs-tabs-position-${_vm.position}`],
    },
    [
      _c("div", { staticClass: "con-ul-tabs" }, [
        _c(
          "ul",
          {
            ref: "ul",
            staticClass: "ul-tabs vs-tabs--ul",
            class: [`ul-tabs-${_vm.alignment}`],
          },
          _vm._l(_vm.children, function (child, index) {
            return _c(
              "li",
              {
                key: index,
                ref: "li",
                refInFor: true,
                staticClass: "vs-tabs--li",
                class: { activeChild: _vm.childActive == index },
                style: _vm.childActive == index ? _vm.styleTab : {},
                on: {
                  mouseover: function ($event) {
                    _vm.hover = true
                  },
                  mouseout: function ($event) {
                    _vm.hover = false
                  },
                },
              },
              [
                _c(
                  "button",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "vs-tabs--btn",
                        style: _vm.styleAlignIcon(child.icon),
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.activeChild(index)
                          },
                        },
                      },
                      "button",
                      child.attrs,
                      false
                    ),
                    child.listeners
                  ),
                  [
                    child.icon
                      ? _c("vs-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: child.iconTooltipContent
                                ? {
                                    content: child.iconTooltipContent,
                                    boundariesElement: "window",
                                  }
                                : null,
                              expression:
                                "child.iconTooltipContent ? {\n                content: child.iconTooltipContent,\n                boundariesElement: 'window',\n              } : null",
                            },
                          ],
                          staticClass: "vs-tabs--btn-icon",
                          attrs: {
                            "icon-pack": child.iconPack,
                            icon: child.icon,
                            color: _vm.color,
                            size: child.iconSize,
                          },
                          on: {
                            mouseenter: function ($event) {
                              return _vm.logTooltip(child.icon)
                            },
                          },
                        })
                      : _vm._e(),
                    child.label
                      ? _c("span", [_vm._v(_vm._s(child.label))])
                      : _vm._e(),
                  ],
                  1
                ),
                child.tag
                  ? _c(
                      "button",
                      {
                        staticClass: "vs-tabs--btn-tag",
                        on: {
                          click: function ($event) {
                            return _vm.clickTag(child)
                          },
                        },
                      },
                      [
                        _c("vs-icon", {
                          attrs: {
                            "icon-pack": child.iconPack,
                            icon: child.tag,
                            color: child.tagColor,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _c("span", { staticClass: "line-vs-tabs", style: _vm.stylex }),
      ]),
      _c("div", { staticClass: "con-slot-tabs" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }