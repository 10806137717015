var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      {
        ref: "card",
        staticClass: "vx-card",
        class: [
          { "overflow-hidden": _vm.tempHidden },
          { "no-shadow": _vm.noShadow },
          { "rounded-none": _vm.noRadius },
          { "card-border": _vm.cardBorder },
          _vm.cardClasses,
        ],
        style: _vm.cardStyles,
      },
      _vm.$listeners
    ),
    [
      _vm.hasHeader
        ? _c("div", { staticClass: "vx-card__header" }, [
            _c(
              "div",
              {
                staticClass: "vx-card__title",
                class: { "w-full": !_vm.hasAction, "w-11/12": _vm.hasAction },
              },
              [
                this.$props.title
                  ? _c(
                      "h4",
                      {
                        staticClass: "break-words",
                        class: _vm.titleClasses,
                        style: _vm.titleStyles,
                      },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    )
                  : _vm._e(),
                this.$props.subtitle
                  ? _c(
                      "h6",
                      { class: _vm.subtitleClasses, style: _vm.subtitleStyles },
                      [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "vx-card__actions" },
              [
                _vm._t("actions", function () {
                  return [
                    (_vm.actionButtons ||
                      _vm.collapseAction ||
                      _vm.refreshContentAction ||
                      _vm.removeCardAction) &&
                    !_vm.codeToggler
                      ? _c(
                          "div",
                          { staticClass: "vx-card__action-buttons" },
                          [
                            _vm.actionButtons || _vm.collapseAction
                              ? _c("feather-icon", {
                                  staticClass: "ml-4",
                                  class: { rotate180: !_vm.isContentCollapsed },
                                  attrs: { icon: "ChevronUpIcon" },
                                  on: { click: _vm.toggleContent },
                                })
                              : _vm._e(),
                            _vm.actionButtons || _vm.refreshContentAction
                              ? _c("feather-icon", {
                                  staticClass: "ml-4",
                                  attrs: { icon: "RotateCwIcon" },
                                  on: { click: _vm.refreshcard },
                                })
                              : _vm._e(),
                            _vm.actionButtons || _vm.removeCardAction
                              ? _c("feather-icon", {
                                  staticClass: "ml-4",
                                  attrs: { icon: "XIcon" },
                                  on: { click: _vm.removeCard },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.codeToggler && !_vm.actionButtons
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vx-card__code-toggler sm:block hidden",
                          },
                          [
                            _c("feather-icon", {
                              class: {
                                "border border-solid border-primary border-t-0 border-r-0 border-l-0":
                                  _vm.showCode,
                              },
                              attrs: { icon: "CodeIcon" },
                              on: { click: _vm.toggleCode },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "vx-card__collapsible-content vs-con-loading__container",
          class: [
            { collapsed: _vm.isContentCollapsed },
            { "overflow-hidden": _vm.tempHidden },
          ],
          style: _vm.StyleItems,
        },
        [
          _vm._t("no-body"),
          this.$slots.default
            ? _c(
                "div",
                { staticClass: "vx-card__body" },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm._t("no-body-bottom"),
          this.$slots.footer
            ? _c(
                "div",
                { staticClass: "vx-card__footer" },
                [_vm._t("footer")],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }